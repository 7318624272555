<script setup>
import { useStore } from "vuex";
import { useApiQuery } from "@/composables/useApiQueries";
import { useI18n } from "vue-i18n/index";
import CardsSmall from "@/components/addresses/CardsSmall.vue";
import TaskWrapperComponent from "@/components/tasks/TaskWrapperComponent.vue";
const store = useStore();
const { t } = useI18n();
const { result: usrAddresses, query: getUsrAddresses } = useApiQuery({
  url: "search/addresses/latest",
  defaultVal: [],
  params: {
    params: {
      user_id: store.getters.user.id,
    },
  },
});
const { result: createdAddresses, query: getCreatedAddresses } = useApiQuery({
  url: "search/addresses/latest",
  defaultVal: [],
  params: {
    params: {
      type: "created",
    },
  },
});
const { result: updatedAddresses, query: getUpdatedAddresses } = useApiQuery({
  url: "search/addresses/latest",
  defaultVal: [],
});
async function getAll() {
  await Promise.all([getUsrAddresses(), getCreatedAddresses(), getUpdatedAddresses()]);
}
getAll();
</script>

<template>
  <div class="container-fluid-max mt-3 px-1">
    <h1 class="text-center mb-3">{{ t("general.dashboard") }}</h1>
    <div class="dashboard-addresses pb-3 mb-3 border-bottom">
      <h2 class="text-center">{{ t("nav.addresses") }}</h2>
      <details class="my-2">
        <summary class="gws_summary">{{ t("address.latest_edited_by") }} {{ store.getters.user.name }}</summary>
        <div class="gws_address_search gws_address_search_sd">
          <CardsSmall :addresses="usrAddresses" />
        </div>
      </details>
      <details class="my-2">
        <summary class="gws_summary">{{ t("address.latest_created_by") }} {{ t("general.all_users") }}</summary>
        <div class="gws_address_search gws_address_search_sd">
          <CardsSmall :addresses="createdAddresses" />
        </div>
      </details>
      <details class="my-2">
        <summary class="gws_summary">{{ t("address.latest_edited_by") }} {{ t("general.all_users") }}</summary>
        <div class="gws_address_search gws_address_search_sd">
          <CardsSmall :addresses="updatedAddresses" />
        </div>
      </details>
    </div>
    <div class="dashboard-tasks pb-3 mb-3 border-bottom">
      <TaskWrapperComponent />
      <!-- <h2 class="text-center">{{ t("general.tasks") }}</h2>
      <div class="gws_address_search gws_address_search_sd">
        <task-component taskType="creator" />
      </div>
      <div class="gws_address_search gws_address_search_sd">
        <task-component taskType="assignee" />
      </div> -->
      <!-- <details class="my-2">
        <summary class="gws_summary">{{ t("general.tasks_created_by_me") }}</summary>
        <div class="gws_address_search gws_address_search_sd">
          <task-component />
        </div>
      </details> -->
      <!-- <details class="my-2">
        <summary class="gws_summary">{{ t("general.tasks_assigned_to_me") }}</summary>
        <div class="gws_address_search gws_address_search_sd">
          <p>Foo</p>
        </div>
      </details> -->
    </div>
  </div>
</template>

<style scoped>
.gws_summary {
  cursor: pointer;
}
/* h1 {
  border-bottom: 1px solid var(--clr-base-d400);
} */
</style>
