<script setup>
import { defineProps } from "vue";
import { useI18n } from "vue-i18n/index";
import { phone, mobile, fax, website } from "@/composables/address.js";
const props = defineProps({
  addresses: {
    type: Array,
    default: () => [],
  },
});
const { t } = useI18n();
</script>
<template>
  <div class="card-group gws_card-group gws_card-group-gap" v-if="props.addresses.length > 0">
    <div class="card gws_search_results text-dark" v-for="address in props.addresses" :key="address.id">
      <div class="card-header d-flex justify-content-end">
        <router-link
          :to="'/addresses/edit/' + address.id"
          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
          :title="t('general.edit')"
          @click="searchModal = !searchModal"
        >
          <svg width="20" height="20" class="bi" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
          </svg>
          {{ t("general.edit") }}
        </router-link>
      </div>
      <div class="card-body">
        <div class="card-text">
          <dl>
            <template v-if="address.name !== null">
              <dt>{{ t("address.name") }}</dt>
              <dd>{{ address.name }}</dd>
            </template>
            <template v-if="address.surname !== null || address.forename !== null">
              <dt>{{ t("address.person_name") }}</dt>
              <dd>{{ address.salutation }} {{ address.forename }} {{ address.surname }}</dd>
            </template>
            <template
              v-if="
                address.street !== null ||
                address.plz !== null ||
                address.postcode !== null ||
                address.city !== null ||
                address.country_id !== null
              "
            >
              <dt>{{ t("address.address") }}</dt>
              <dd>
                <span v-if="address.street">{{ address.street }} </span><br />
                <span v-if="address.plz">{{ address.plz }}&nbsp;</span>
                <span v-if="address.city">{{ address.city }}</span
                ><br />
                <span v-if="address.country_id">{{ address.country.name }} </span>
                <span v-if="address.postcode"><br />{{ t("address.postbox") }}: {{ address.postcode }} </span>
              </dd>
            </template>
            <template v-if="address.email !== null">
              <dt>{{ t("address.email") }}</dt>
              <dd>
                <a :href="'mailto:' + address.email">{{ address.email }}</a>
              </dd>
            </template>
            <template v-if="address.tel !== null">
              <dt>{{ t("address.tel") }}</dt>
              <dd>
                <a :href="'tel:' + phone(address)">{{ phone(address) }}</a>
              </dd>
            </template>
            <template v-if="address.mobile !== null">
              <dt>{{ t("address.mobile") }}</dt>
              <dd>
                <a :href="'tel:' + mobile(address)">{{ mobile(address) }}</a>
              </dd>
            </template>
            <template v-if="address.fax !== null">
              <dt>{{ t("address.fax") }}</dt>
              <dd>
                {{ fax(address) }}
              </dd>
            </template>
            <template v-if="address.website !== null">
              <dt>{{ t("address.website") }}</dt>
              <dd>
                <a :href="website(address.website)" target="_blank" rel="noopener noreferrer">{{
                  website(address.website)
                }}</a>
              </dd>
            </template>
          </dl>
          <div class="d-flex mb-2">
            <span v-if="address.is_company == 1" class="badge rounded-pill bg-secondary me-1">{{
              t("general.company")
            }}</span>
            <span v-if="address.is_person == 1" class="badge rounded-pill bg-secondary me-1">{{
              t("general.person")
            }}</span>
          </div>
          <div class="d-flex flex-wrap">
            <span
              v-for="category in address.categories"
              :key="category.id"
              class="badge rounded-pill bg-primary me-1 mb-1"
              >{{ category.category }}</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="card-footer bg-transparent border-success">Footer</div> -->
    </div>
  </div>
</template>
