<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import TaskComponent from "@/components/tasks/TaskComponent.vue";
const store = useStore();
const { t } = useI18n();
const currentUser = computed(() => store.getters.user);

const statusesByCreator = ref({
  open: 0,
  reopened: 0,
  resolved: 0,
  closed: 0,
});
const statusesByAssignee = ref({
  open: 0,
  reopened: 0,
  resolved: 0,
  closed: 0,
});

const { result: tasksByCreator, query: getTasksByCreator } = useApiQuery({
  url: "search/task/bycreator",
  params: {
    params: {
      id: currentUser.value.id,
    },
  },
  defaultVal: [],
  pfn: (data) => {
    data.forEach((task) => {
      statusesByCreator.value[task.status]++;
    });
    return data;
  },
});

const { result: tasksByAssignee, query: getTasksByAssignee } = useApiQuery({
  url: "search/task/byassignee",
  params: {
    params: {
      id: currentUser.value.id,
    },
  },
  defaultVal: [],
  pfn: (data) => {
    data.forEach((task) => {
      statusesByAssignee.value[task.status]++;
    });
    return data;
  },
});

const ResetStatusCount = () => {
  statusesByCreator.value = {
    open: 0,
    reopened: 0,
    resolved: 0,
    closed: 0,
  };
  statusesByAssignee.value = {
    open: 0,
    reopened: 0,
    resolved: 0,
    closed: 0,
  };
};

async function getUpdatedTasks() {
  ResetStatusCount();
  await Promise.all([getTasksByCreator(), getTasksByAssignee()]);
}

async function getAll() {
  await Promise.all([store.dispatch("loadUsers"), getTasksByCreator(), getTasksByAssignee()]);
}
getAll();
</script>
<template>
  <h2 class="text-center">{{ t("general.tasks") }}</h2>
  <div class="gws_address_search gws_address_search_sd">
    <task-component
      :tasks="tasksByCreator"
      :statusCounters="statusesByCreator"
      taskType="creator"
      @update-task="getUpdatedTasks"
    />
  </div>
  <div class="gws_address_search gws_address_search_sd">
    <task-component
      :tasks="tasksByAssignee"
      :statusCounters="statusesByAssignee"
      taskType="assignee"
      @update-task="getUpdatedTasks"
    />
  </div>
</template>
