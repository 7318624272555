<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useApiQuery } from "@/composables/useApiQueries";
import { useI18n } from "vue-i18n/index";
import { nl2br, localDateTime } from "@/composables/utilities";
import { useToast } from "vue-toastification";
const router = useRouter();
const store = useStore();
const { t } = useI18n();
const toast = useToast();
const props = defineProps({
  taskType: String,
  tasks: Array,
  statusCounters: Object,
});
const emit = defineEmits(["updateTask"]);
const currentUser = computed(() => store.getters.user);
const users = computed(() => store.getters.users);
const getUserById = (id) => {
  return users.value.find((user) => user.id === id);
};
const statuses = ref([
  { value: "comment", text: t("general.comment"), bgColor: "#666", open: false },
  { value: "open", text: t("general.open"), bgColor: "#cc6d00", open: false },
  { value: "reopened", text: t("general.reopened"), bgColor: "#a51d2a", open: false },
  { value: "resolved", text: t("general.resolved"), bgColor: "#00a600", open: false }, // #627313
  { value: "closed", text: t("general.closed"), bgColor: "#333", open: false },
]);

const statusList = computed(() => {
  return statuses.value.filter((status) => props.statusCounters[status.value] > 0 && status.value !== "comment");
});

const setStatusOpen = (type) => {
  statuses.value.forEach((status) => {
    if (status.value === type) {
      status.open = true;
    }
  });
};

const filterStatusSelect = computed(() => {
  if (props.taskType === "assignee") {
    return {
      open: ["comment", "resolved"],
      reopened: ["comment", "resolved"],
      resolved: ["comment", "reopened"],
      closed: ["comment"],
    };
  } else {
    return {
      open: ["comment", "resolved", "closed"],
      reopened: ["comment", "resolved", "closed"],
      resolved: ["comment", "reopened", "closed"],
      closed: ["comment", "reopened"],
    };
  }
});

const getStatus = (type) => {
  return statuses.value.find((status) => status.value === type);
};
const selectedTask = ref(null);
const setSelectedTask = (id) => {
  if (selectedTask.value === id) {
    selectedTask.value = null;
  } else {
    selectedTask.value = id;
  }
};
const newTaskMeta = ref({
  task_id: null,
  user_id: currentUser.value.id,
  type: "comment",
  comment: "",
});
const addTaskMeta = async (id, currentStatus) => {
  newTaskMeta.value.task_id = id;
  try {
    await postTaskMeta({ params: newTaskMeta.value });
    if (errorPostTaskMeta.value) {
      toast.error(t("error.errorOccured") + " " + errorPostTaskMeta.value.response.status);
    } else {
      if (newTaskMeta.value.type !== "comment") {
        await updateTask({
          url: "task/" + id,
          params: {
            id: id,
            status: newTaskMeta.value.type,
          },
        });
        if (errorUpdateTask.value) {
          toast.error(t("error.errorOccured") + " " + errorUpdateTask.value.response.status);
        }
      }
      toast.success(t("general.task") + " " + t("general.successfully") + " " + t("general.added") + ".");
    }
    if (newTaskMeta.value.type !== "comment") {
      setStatusOpen(newTaskMeta.value.type);
    } else {
      setStatusOpen(currentStatus);
    }
    newTaskMeta.value.task_id = null;
    newTaskMeta.value.type = "comment";
    newTaskMeta.value.comment = "";
    emit("updateTask");
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const { query: postTaskMeta, error: errorPostTaskMeta } = useApiQuery({
  url: "taskmeta",
  method: "post",
  defaultVal: [],
});

const { query: updateTask, error: errorUpdateTask } = useApiQuery({
  method: "patch",
  defaultVal: [],
});

const title = computed(() => {
  if (props.taskType === "assignee") {
    return t("general.tasks_assigned_to_me");
  } else {
    return t("general.tasks_created_by_me");
  }
});
</script>
<template>
  <h3 class="text-center">{{ title }}</h3>
  <details v-for="status in statusList" :key="'show_task_status_' + status.value" :open="status.open" class="my-2">
    <summary class="gws_summary">
      <div class="summary-status">
        {{ status.text }}
      </div>
      <div class="summary-num" :style="'background-color:' + status.bgColor">{{ statusCounters[status.value] }}</div>
    </summary>
    <div class="container-fluid my-4">
      <template v-for="task in tasks" :key="task.id">
        <div
          v-if="status.value === task.status"
          @dblclick="setSelectedTask(task.id)"
          class="row tbl-brdb gws_slice gws_slice_row"
        >
          <div class="col-lg-2 col-xxl-1 py-1">#{{ task.id }}</div>
          <div class="col-lg py-1">
            <RouterLink
              :to="'/' + task.task_type + '?task=' + task.id"
              class="btn btn-primary btn-icon-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg class="bi" width="20" height="20" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
              </svg>
              {{ t("general.task") }}
            </RouterLink>
          </div>
          <div class="col-lg-2 col-xxl-1 text-end d-flex align-items-center justify-content-end py-1">
            <button @click="setSelectedTask(task.id)" class="btn btn-success btn-icon-sm w-100" title="Bearbeiten">
              <svg class="bi" width="18" height="18" fill="currentColor">
                <use
                  :xlink:href="
                    selectedTask === task.id
                      ? '/assets/img/bootstrap-icons.svg#chevron-up'
                      : '/assets/img/bootstrap-icons.svg#chevron-down'
                  "
                ></use>
              </svg>
              {{ t("general.edit") }}
            </button>
          </div>
        </div>
        <div
          v-if="selectedTask === task.id && status.value === task.status"
          class="row mb-4 gws_slice gws_slice_bottom"
        >
          <div class="col-lg-12">
            <dl class="row my-2">
              <dt class="col-sm-3">{{ t("user.user") }}</dt>
              <dd class="col-sm-9">
                <div
                  v-for="user in task.users"
                  :key="user.id"
                  class="d-inline-block me-1 mb-1 pe-3 badge rounded-pill bg-primary"
                >
                  <svg class="bi ms-1 cursor-pointer" width="20" height="20" fill="currentColor">
                    <use xlink:href="/assets/img/bootstrap-icons.svg#person"></use>
                  </svg>
                  {{ user.name }}
                </div>
              </dd>
            </dl>
            <div>
              <h4>{{ t("general.history") }}</h4>
              <dl v-for="meta in task.metas" :key="meta.id" class="row border-top pt-2">
                <dt class="col-sm-3">{{ t("general.created_at") }}</dt>
                <dd class="col-sm-9">{{ localDateTime(meta.created_at, "de-DE") }}</dd>
                <dt class="col-sm-3">{{ t("general.from") }}</dt>
                <dd class="col-sm-9">{{ getUserById(meta.user_id).name }}</dd>
                <dt class="col-sm-3">{{ t("general.type") }}</dt>
                <dd class="col-sm-9">
                  <span class="task-type rounded p-1" :style="'background-color:' + getStatus(meta.type).bgColor">{{
                    getStatus(meta.type).text
                  }}</span>
                </dd>
                <dt class="col-sm-3">{{ t("general.comment") }}</dt>
                <dd class="col-sm-9">
                  <div class="border rounded p-2" v-html="nl2br(meta.comment)"></div>
                </dd>
              </dl>
            </div>
            <dl class="row my-2 add-task-meta border-top pt-2">
              <dt class="col-sm-3">{{ t("general.add") }}</dt>
              <dd class="col-sm-9">
                <div class="form-floating mb-2">
                  <select v-model="newTaskMeta.type" class="form-select" name="type" id="add-task-meta-type">
                    <template v-for="statusSelect in statuses" :key="'status_' + statusSelect.value">
                      <option
                        v-if="filterStatusSelect[status.value].includes(statusSelect.value)"
                        :value="statusSelect.value"
                        class="status-select"
                        :style="'background-color:' + statusSelect.bgColor"
                      >
                        {{ statusSelect.text }}
                      </option>
                    </template>
                  </select>
                  <label for="add-task-meta-type" class="form-label">{{ t("general.type") }}</label>
                </div>
                <div class="form-floating">
                  <textarea
                    v-model="newTaskMeta.comment"
                    class="form-control task-comment"
                    id="comment"
                    rows="3"
                  ></textarea>
                  <label for="comment" class="form-label">{{ t("general.comment") }}</label>
                </div>
                <button @click="addTaskMeta(task.id, status.value)" class="btn btn-primary mt-2">
                  {{ t("general.add") }}
                </button>
              </dd>
            </dl>
          </div>
        </div>
      </template>
    </div>
  </details>
</template>
<style lang="scss" scoped>
.summary-status {
  display: inline-block;
}
.summary-num {
  text-align: right;
  display: block;
  float: right;
  margin-top: 0.25rem;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #fff !important;
  border-radius: 4px;
  min-width: 2rem;
  text-align: center;
}
.status-select {
  color: #fff;
}
.task-type {
  color: #fff;
}
.task-comment {
  height: 200px;
}
</style>
